import {
  Divider,
  Drawer as DrawerMui,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CadastroIcon from '@material-ui/icons/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { usePermission } from '../../hooks/permission';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';
import userMasculino from '../../assets/user_masculino.png';
import userFeminino from '../../assets/user_feminino.png';
import semImagem from '../../assets/img_indisponivel.png';

interface ToggleProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  mobileOpen: boolean;
  pathname: string;
  logoImg: string;
  userName: string;
}

export const AppDrawer: React.FC<ToggleProps> = props => {
  const classes = useStyles();

  const { mobileOpen, handleDrawerToggle, pathname } = props;

  const { isAuthorized, foto, discipulo } = usePermission();

  const { signOut } = useAuth();

  const [fotoDiscipulo] = useState<any>(() => {
    if (foto) {
      return foto;
    }
    if (discipulo && discipulo.sexo) {
      if (discipulo.sexo === 'feminino') {
        return userFeminino;
      }
      return userMasculino;
    }
    return userMasculino;
  });

  const [openCadastroAdministracao, setOpenCadastroAdministracao] =
    React.useState(false);
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [openCasaPaz, setOpenCasaPaz] = React.useState(false);
  const [openCelula, setOpenCelula] = React.useState(false);
  const [openEvento, setOpenEvento] = React.useState(false);
  const [openBatismo, setOpenBatismo] = React.useState(false);
  const [openDiscipulo, setOpenDiscipulo] = React.useState(false);
  const [openDepartamento, setOpenDepartamento] = React.useState(false);
  const [openContabilidade, setOpenContabilidade] = React.useState(false);
  const [openParametro, setOpenParametro] = React.useState(false);
  const [openConselhoEtica, setOpenConselhoEtica] = React.useState(false);
  const [openAltar, setOpenAltar] = React.useState(false);
  const [openRelatorio, setOpenRelatorio] = React.useState(false);

  // Permission of user group

  // Parametrizações
  const parametroParametrizacoesGerais = isAuthorized(
    'e92e0fab-7150-4c4c-9fa7-4330ecd5e3b3',
  );

  const parametros = parametroParametrizacoesGerais;

  // Altar
  const altarPregador = isAuthorized('ecaf56a9-5f6c-4b1d-9300-df54496f8d4d');
  const altarAbertura = isAuthorized('4abbb873-35da-441d-b16d-904f17108761');
  const altarMeio = isAuthorized('56202787-72ec-4b51-a395-0601b8285321');
  const altarEscala = isAuthorized('4588254d-1db5-4828-8c82-46bd803a15c0');

  const altar = altarPregador || altarAbertura || altarMeio || altarEscala;

  // Conselho Etica
  const conselhoEticaDenuncia = isAuthorized(
    'f1174a17-2018-4bfc-90dd-b213d687e31a',
  );

  const conselhoEtica = conselhoEticaDenuncia;

  // Cadastros Administracao
  const cadastroGrupoUsuarioAdministracao = isAuthorized(
    'e0088523-f072-4060-9805-9d75758b927f',
  );
  const cadastroMenuAdministracao = isAuthorized(
    '9fd323c8-b9e6-49b2-aecf-da3d1b481727',
  );
  const cadastroSubMenuAdministracao = isAuthorized(
    'c40bb499-b86f-4e20-9806-7462d1f8d068',
  );
  const cadastroGrupoPermissaoAdministracao = isAuthorized(
    '9cd8e300-d75b-4c92-b837-fedb647632f7',
  );
  const cadastroUsuarioGrupoAdministracao = isAuthorized(
    'c414e0ad-ef09-4149-b327-2288daa54a64',
  );
  const cadastroUsuarioAdministracao = isAuthorized(
    '79cdb519-20af-4844-b0d9-1b74602a6e4a',
  );

  const cadastrosAdministracao =
    cadastroGrupoPermissaoAdministracao ||
    cadastroUsuarioGrupoAdministracao ||
    cadastroGrupoUsuarioAdministracao ||
    cadastroMenuAdministracao ||
    cadastroSubMenuAdministracao ||
    cadastroUsuarioAdministracao;

  // Cadastros
  const cadastroDepartamento = isAuthorized(
    '1ce7645f-debf-44f3-892c-d519b2d686a8',
  );
  const cadastroDiscipulo = isAuthorized(
    '0ab7c718-aed3-452b-b0f8-f152603f0b1b',
  );
  const cadastroVisitante = isAuthorized(
    'b570a88f-e943-4aa8-8074-a17227ec2b1b',
  );
  const cadastroLider = isAuthorized('92471a59-2e37-4df1-bcf9-864872ce5a7f');
  const discipulosQRCode = isAuthorized('');

  const cadastros =
    cadastroDepartamento ||
    cadastroDiscipulo ||
    cadastroVisitante ||
    discipulosQRCode ||
    cadastroLider;

  // Departamento
  const departamentoEscala = isAuthorized(
    'd3f6f4fe-9cf7-4d65-b514-c5b99d9a7e72',
  );
  const departamentoListaPresenca = isAuthorized('');
  const departamentoListaPresencaGeral = isAuthorized(
    'cccfa61e-8799-4bca-8d10-064d04e436e3',
  );
  const departamentoSituacaoLider = isAuthorized(
    '664f5ff3-da79-42cb-89ae-df63f8af66b9',
  );
  const departamentoReuniaoSituacaoLider = isAuthorized(
    '0804953e-629d-47b4-b500-e1d6c8f2514e',
  );
  const departamentoSituacaoLiderGeral = isAuthorized(
    '2fe47382-7ae1-4e73-8b5f-237f6c10ed57',
  );
  const departamentoSituacaoLiderGeralReuniao = isAuthorized(
    '07bfeecb-11ff-49d3-be5a-8e0654e697fd',
  );
  const departamentoSituacaoLiderDepartamentoGeral = isAuthorized(
    '17863502-243f-4b1c-9370-b42f12d3b0c6',
  );
  const departamentoSituacaoLiderDepartamentoGeralReuniao = isAuthorized(
    'a719c96c-eda7-42fd-9b33-3225fb49377c',
  );
  const departamentoSolicitacaoLink = isAuthorized('');

  const departamentos =
    departamentoEscala ||
    departamentoListaPresenca ||
    departamentoListaPresencaGeral ||
    departamentoSituacaoLider ||
    departamentoSituacaoLiderGeral ||
    departamentoSituacaoLiderGeralReuniao ||
    departamentoSituacaoLiderDepartamentoGeral ||
    departamentoSituacaoLiderDepartamentoGeralReuniao ||
    departamentoSolicitacaoLink ||
    departamentoReuniaoSituacaoLider;

  // Discipulo
  const discipuloPerfil = !!discipulo;
  const discipuloCarteirinha = !!discipulo;
  const discipuloQRCode = !!discipulo;
  const discipuloVideoPlayer = !!discipulo && isAuthorized('');
  const discipuloEscala =
    !!discipulo && isAuthorized('25b3f4f6-88c6-4fb0-9c2e-36e7675ccdf1');
  const discipuloComprovante =
    !!discipulo && isAuthorized('0b7555b2-cbd9-4cd3-911a-5ee5cb51c9ee');

  const discipulos =
    discipuloPerfil ||
    discipuloCarteirinha ||
    discipuloQRCode ||
    discipuloVideoPlayer ||
    discipuloEscala ||
    discipuloComprovante;

  // Batismo
  const batismoCadastro = isAuthorized('09789c6d-9e2a-44a8-9249-85016fb5a377');
  const batismoBatizante = isAuthorized('c36dabb6-eeb8-4bd7-867e-9cb38c21b600');
  const batismoCheckin = isAuthorized('b7984f0b-2094-4b89-bb59-b1b42d314413');
  const batismoCheckinToten = isAuthorized(
    '18a9b802-437a-49c8-8514-0c711f878a2e',
  );
  const batismoCheckinManual = isAuthorized(
    '381e5fa5-a0bf-4693-ab45-6132b24b631a',
  );
  const batismoListaPresenca = isAuthorized(
    '1a142e52-3530-4998-81b3-fb2658e9c167',
  );

  const batismos =
    batismoCadastro ||
    batismoBatizante ||
    batismoCheckin ||
    batismoCheckinToten ||
    batismoCheckinManual ||
    batismoListaPresenca;

  // Casas de Paz
  const casaPazCadastro = isAuthorized('cdc69ad1-7d3c-4d44-9fa7-57c3791c8885');
  const casaPazBatizante = isAuthorized('b61a2ac8-0d43-4c2d-b94c-3f2204bb9f2f');

  const casasPaz = casaPazCadastro || casaPazBatizante;

  // Celulas
  const celulaCadastro = isAuthorized('b260dccd-0d82-4979-b2f7-abff7d514bc9');
  const celulaCoordenacao = isAuthorized(
    '8e060229-1ec9-4478-84c4-0a136c859c59',
  );
  const celulaSupervisao = isAuthorized('e0992635-596d-465b-9013-24d2b12c16a3');
  const celulaLocalizador = isAuthorized(
    '4e33b853-43b5-463c-a69b-cd3aea4a1bed',
  );
  const celulaMapa = isAuthorized('86faeb8c-21ba-4a02-8a56-3c73937fb934');
  const celulaTriagem = isAuthorized('f52c4400-1a44-4e3b-947b-d6a0f4e081da');
  const celulaTriagemBatizante = isAuthorized(
    '509e45a1-9fb8-422a-87d5-b31c31cc6c2a',
  );

  const celulas =
    celulaCadastro ||
    celulaCoordenacao ||
    celulaSupervisao ||
    celulaLocalizador ||
    celulaMapa;

  // Contabilidade
  const contabilidadeDizimistas = isAuthorized('');
  const contabilidadeComprovantes = isAuthorized(
    '24b0be35-cdd3-4e77-b869-606ef904cb1d',
  );

  const contabilidade = contabilidadeDizimistas || contabilidadeComprovantes;

  // Relatórios
  const relatorioListaPresencaEscolaSabedoria = isAuthorized(
    '3723893e-390f-476d-856c-70774feeb6e0',
  );

  const relatorioListaPresencaReuniao = isAuthorized('');

  const relatorio =
    relatorioListaPresencaEscolaSabedoria || relatorioListaPresencaReuniao;

  // Evento
  const eventoCadastro = isAuthorized('e8a90e4d-b789-4012-945e-cab7fe9ee498');
  const eventoProgramacaoRecorrente = isAuthorized('');
  const eventoEtiqueta = isAuthorized('f58c79a7-aae1-4960-b29c-0d2d12b3c219');
  const eventoCheckin = isAuthorized('43a43243-a6d0-4329-800a-9987538dc293');
  const eventoCheckinToten = isAuthorized(
    '18029c35-1046-4c38-a1fc-3870d32301ec',
  );
  const eventoCheckinManual = isAuthorized(
    '2f955fdc-8563-4680-976c-9725e9d22f34',
  );
  const eventoCheckinManualCentral = isAuthorized(
    'efc0bd2f-b2ac-48db-89fe-c4d5c39d1817',
  );
  const eventoCheckinHibrido = isAuthorized(
    '4f0e8ac2-ab4f-437d-ac31-704578a3fe5c',
  );
  const eventoCheckinNFC = isAuthorized('4d50c1a1-c48f-4224-8720-1a55ff49ec96');
  const eventoPainel = isAuthorized('2b4a343d-2c8c-46b8-9ecd-8320011a42c2');
  const eventoInscricao = isAuthorized('5cc677ca-80d7-4bd6-8a74-691b3c1ca50c');
  const eventoCredenciamento = isAuthorized(
    '9d5ce16c-c4c4-4de6-9f0e-e2b18c633f51',
  );
  const eventoQRCodeScanner = isAuthorized(
    '9e96f39b-07c9-480b-bd9e-8fa0ca4fc149',
  );

  const eventos =
    eventoCadastro ||
    eventoProgramacaoRecorrente ||
    eventoCheckin ||
    eventoCheckinToten ||
    eventoCheckinManual ||
    eventoCheckinManualCentral ||
    eventoCheckinHibrido ||
    eventoCheckinNFC ||
    eventoPainel ||
    eventoInscricao ||
    eventoCredenciamento ||
    eventoQRCodeScanner;

  const handleListOpenClick = (folder: string): void => {
    switch (folder) {
      case 'cadastro_administracao':
        setOpenCadastroAdministracao(!openCadastroAdministracao);
        break;
      case 'altar':
        setOpenAltar(!openAltar);
        break;
      case 'cadastro':
        setOpenCadastro(!openCadastro);
        break;
      case 'parametro':
        setOpenParametro(!openParametro);
        break;
      case 'conselhoEtica':
        setOpenConselhoEtica(!openConselhoEtica);
        break;
      case 'casaPaz':
        setOpenCasaPaz(!openCasaPaz);
        break;
      case 'celula':
        setOpenCelula(!openCelula);
        break;
      case 'evento':
        setOpenEvento(!openEvento);
        break;
      case 'batismo':
        setOpenBatismo(!openBatismo);
        break;
      case 'departamento':
        setOpenDepartamento(!openDepartamento);
        break;
      case 'discipulo':
        setOpenDiscipulo(!openDiscipulo);
        break;
      case 'contabilidade':
        setOpenContabilidade(!openContabilidade);
        break;
      case 'relatorio':
        setOpenRelatorio(!openRelatorio);
        break;
      default:
        break;
    }
  };

  const handleImgOnError = (): void => {
    const imgField = document.querySelector('#imgField') as HTMLImageElement;
    if (imgField) {
      imgField.src = semImagem;
    }
  };

  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <img
          id="imgField"
          src={fotoDiscipulo}
          alt="Foto"
          height="45px"
          className={classes.imageHeader}
          onError={handleImgOnError}
        />
        <div className={classes.userHeader}>
          <Typography variant="subtitle2" color="inherit" noWrap>
            {discipulo?.nome}
          </Typography>
          <Typography variant="subtitle2" color="inherit" noWrap>
            Seja bem vindo
          </Typography>
        </div>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/home"
          selected={pathname === '/home'}
        >
          <ListItemIcon>
            <HomeIcon className={classes.button} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
      <Divider />
      {cadastrosAdministracao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('cadastro_administracao')}
        >
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Administração" />
          {openCadastroAdministracao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openCadastroAdministracao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {cadastroGrupoUsuarioAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/grupo"
              selected={pathname === '/grupo'}
            >
              <ListItemText primary="Grupo" />
            </ListItem>
          )}
          {cadastroMenuAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/menu"
              selected={pathname === '/menu'}
            >
              <ListItemText primary="Menu" />
            </ListItem>
          )}
          {cadastroSubMenuAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/sub-menu"
              selected={pathname === '/sub-menu'}
            >
              <ListItemText primary="Sub Menu" />
            </ListItem>
          )}
          {cadastroGrupoPermissaoAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/grupo-permissao"
              selected={pathname === '/grupo-permissao'}
            >
              <ListItemText primary="Grupo Permissão" />
            </ListItem>
          )}
          {cadastroUsuarioGrupoAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/usuario-grupo"
              selected={pathname === '/usuario-grupo'}
            >
              <ListItemText primary="Usuário Grupo" />
            </ListItem>
          )}
          {cadastroUsuarioAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/usuario"
              selected={pathname === '/usuario'}
            >
              <ListItemText primary="Usuário" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {cadastros && (
        <ListItem button onClick={() => handleListOpenClick('cadastro')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastros" />
          {openCadastro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openCadastro} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {cadastroDepartamento && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/departamento"
              selected={pathname === '/departamento'}
            >
              <ListItemText primary="Departamento" />
            </ListItem>
          )}
          {cadastroDiscipulo && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/discipulo"
              selected={pathname === '/discipulo'}
            >
              <ListItemText primary="Discípulo" />
            </ListItem>
          )}
          {cadastroVisitante && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/visitante"
              selected={pathname === '/visitante'}
            >
              <ListItemText primary="Visitante" />
            </ListItem>
          )}
          {cadastroLider && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lideranca"
              selected={pathname === '/lideranca'}
            >
              <ListItemText primary="Liderança" />
            </ListItem>
          )}
          {discipulosQRCode && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/discipulos-qr-code"
              selected={pathname === '/discipulos-qr-code'}
            >
              <ListItemText primary="Lista Qr Code" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {parametros && (
        <ListItem button onClick={() => handleListOpenClick('parametro')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Parametrizações" />
          {openParametro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openParametro} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {parametroParametrizacoesGerais && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/parametros-gerais"
              selected={pathname === '/parametros-gerais'}
            >
              <ListItemText primary="Parâmetros Gerais" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {altar && (
        <ListItem button onClick={() => handleListOpenClick('altar')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Altar" />
          {openAltar ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openAltar} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {altarPregador && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/altar-pregador"
              selected={pathname === '/altar-pregador'}
            >
              <ListItemText primary="Pregador(a)" />
            </ListItem>
          )}
          {altarAbertura && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/altar-abertura"
              selected={pathname === '/altar-abertura'}
            >
              <ListItemText primary="Abertura" />
            </ListItem>
          )}
          {altarMeio && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/altar-meio"
              selected={pathname === '/altar-meio'}
            >
              <ListItemText primary="Meio" />
            </ListItem>
          )}
          {altarEscala && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/altar-escala"
              selected={pathname === '/altar-escala'}
            >
              <ListItemText primary="Escala" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {conselhoEtica && (
        <ListItem button onClick={() => handleListOpenClick('conselhoEtica')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Conselho Ética" />
          {openConselhoEtica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openConselhoEtica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {conselhoEticaDenuncia && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/denuncia-conselho-etica"
              selected={pathname === '/denuncia-conselho-etica'}
            >
              <ListItemText primary="Denúncias" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {batismos && (
        <ListItem button onClick={() => handleListOpenClick('batismo')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Batismo" />
          {openBatismo ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openBatismo} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {batismoCadastro && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/batismo"
              selected={pathname === '/batismo'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {batismoBatizante && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/batizante"
              selected={pathname === '/batizante'}
            >
              <ListItemText primary="Batizante" />
            </ListItem>
          )}
          {batismoCheckin && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-batismo-qrcode"
              selected={pathname === '/checkin-batismo-qrcode'}
            >
              <ListItemText primary="Check-in" />
            </ListItem>
          )}
          {batismoCheckinToten && !mobileOpen && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-batismo-toten"
              selected={pathname === '/checkin-batismo-toten'}
            >
              <ListItemText primary="Check-in Toten" />
            </ListItem>
          )}
          {batismoCheckinManual && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-batismo-manual"
              selected={pathname === '/checkin-batismo-manual'}
            >
              <ListItemText primary="Check-in Manual" />
            </ListItem>
          )}
          {batismoListaPresenca && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lista-presenca-batismo"
              selected={pathname === '/lista-presenca-batismo'}
            >
              <ListItemText primary="Lista Presença" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {eventos && (
        <ListItem button onClick={() => handleListOpenClick('evento')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Evento" />
          {openEvento ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openEvento} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {eventoCadastro && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/evento"
              selected={pathname === '/evento'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {eventoCheckin && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-qrcode"
              selected={pathname === '/checkin-qrcode'}
            >
              <ListItemText primary="Check-in" />
            </ListItem>
          )}
          {eventoCheckinToten && !mobileOpen && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-toten"
              selected={pathname === '/checkin-toten'}
            >
              <ListItemText primary="Check-in Toten" />
            </ListItem>
          )}
          {eventoCheckinNFC && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-nfc"
              selected={pathname === '/checkin-nfc'}
            >
              <ListItemText primary="Check-in NFC" />
            </ListItem>
          )}
          {eventoCheckinManual && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-manual"
              selected={pathname === '/checkin-manual'}
            >
              <ListItemText primary="Check-in Manual" />
            </ListItem>
          )}
          {eventoCheckinManualCentral && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-manual-central"
              selected={pathname === '/checkin-manual-central'}
            >
              <ListItemText primary="Check-in Manual LP" />
            </ListItem>
          )}
          {eventoCheckinHibrido && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/checkin-hibrido"
              selected={pathname === '/checkin-hibrido'}
            >
              <ListItemText primary="Check-in Híbrido" />
            </ListItem>
          )}
          {eventoPainel && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/painel"
              selected={pathname === '/painel'}
            >
              <ListItemText primary="Painel" />
            </ListItem>
          )}
          {eventoInscricao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/inscricao"
              selected={pathname === '/inscricao'}
            >
              <ListItemText primary="Inscrição" />
            </ListItem>
          )}
          {eventoCredenciamento && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/credenciamento"
              selected={pathname === '/credenciamento'}
            >
              <ListItemText primary="Credenciamento" />
            </ListItem>
          )}
          {eventoProgramacaoRecorrente && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/programacao-recorrente"
              selected={pathname === '/programacao-recorrente'}
            >
              <ListItemText primary="Programação" />
            </ListItem>
          )}
          {eventoQRCodeScanner && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/scanner"
              selected={pathname === '/scanner'}
            >
              <ListItemText primary="Scan QR Code" />
            </ListItem>
          )}
          {eventoEtiqueta && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/etiqueta"
              selected={pathname === '/etiqueta'}
            >
              <ListItemText primary="Etiquetas" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {discipulos && (
        <ListItem button onClick={() => handleListOpenClick('discipulo')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Discipulo" />
          {openDiscipulo ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openDiscipulo} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {discipuloPerfil && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/perfil-discipulo"
              selected={pathname === '/perfil-discipulo'}
            >
              <ListItemText primary="Meu cadastro" />
            </ListItem>
          )}
          {discipuloCarteirinha && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/carteirinha"
              selected={pathname === '/carteirinha'}
            >
              <ListItemText primary="Carteirinha" />
            </ListItem>
          )}
          {discipuloQRCode && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/qrcode"
              selected={pathname === '/qrcode'}
            >
              <ListItemText primary="QR Code" />
            </ListItem>
          )}
          {discipuloVideoPlayer && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/videoplayer"
              selected={pathname === '/videoplayer'}
            >
              <ListItemText primary="Video Player" />
            </ListItem>
          )}
          {discipuloEscala && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/escala-discipulo"
              selected={pathname === '/escala-discipulo'}
            >
              <ListItemText primary="Escala" />
            </ListItem>
          )}
          {discipuloComprovante && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/discipulo-comprovante"
              selected={pathname === '/discipulo-comprovante'}
            >
              <ListItemText primary="Comprovantes" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {departamentos && (
        <ListItem button onClick={() => handleListOpenClick('departamento')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Departamento" />
          {openDepartamento ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openDepartamento} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {departamentoEscala && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/escala-departamento"
              selected={pathname === '/escala-departamento'}
            >
              <ListItemText primary="Escala" />
            </ListItem>
          )}
          {departamentoListaPresenca && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lista-presenca"
              selected={pathname === '/lista-presenca'}
            >
              <ListItemText primary="Lista Presença" />
            </ListItem>
          )}
          {departamentoListaPresencaGeral && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lista-presenca-geral"
              selected={pathname === '/lista-presenca-geral'}
            >
              <ListItemText primary="Lista Presença Geral" />
            </ListItem>
          )}
          {departamentoSituacaoLider && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-departamento"
              selected={pathname === '/situacao-lider-departamento'}
            >
              <ListItemText primary="Situação Líder" />
            </ListItem>
          )}
          {departamentoReuniaoSituacaoLider && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-departamento-reuniao"
              selected={pathname === '/situacao-lider-departamento-reuniao'}
            >
              <ListItemText primary="Situação Líder Reunião" />
            </ListItem>
          )}
          {departamentoSituacaoLiderGeral && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-geral"
              selected={pathname === '/situacao-lider-geral'}
            >
              <ListItemText primary="Situação Líder Geral" />
            </ListItem>
          )}
          {departamentoSituacaoLiderGeralReuniao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-geral-reuniao"
              selected={pathname === '/situacao-lider-geral-reuniao'}
            >
              <ListItemText primary="Situação Líder Geral Reunião" />
            </ListItem>
          )}
          {departamentoSituacaoLiderDepartamentoGeral && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-departamento-geral"
              selected={pathname === '/situacao-lider-departamento-geral'}
            >
              <ListItemText primary="Situação Líder Departamento Geral" />
            </ListItem>
          )}
          {departamentoSituacaoLiderDepartamentoGeralReuniao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/situacao-lider-departamento-geral-reuniao"
              selected={
                pathname === '/situacao-lider-departamento-geral-reuniao'
              }
            >
              <ListItemText primary="Situação Líder Departamento Geral Reunião" />
            </ListItem>
          )}
          {departamentoSolicitacaoLink && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/solicitacao-link"
              selected={pathname === '/solicitacao-link'}
            >
              <ListItemText primary="Solicitação Link" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {casasPaz && (
        <ListItem button onClick={() => handleListOpenClick('casaPaz')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Casas de Paz" />
          {openCasaPaz ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openCasaPaz} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {casaPazCadastro && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/casa-paz"
              selected={pathname === '/casa-paz'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {casaPazBatizante && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/casa-paz-batizante"
              selected={pathname === '/casa-paz-batizante'}
            >
              <ListItemText primary="Batizantes" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {celulas && (
        <ListItem button onClick={() => handleListOpenClick('celula')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Células" />
          {openCelula ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openCelula} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {celulaCadastro && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/celula"
              selected={pathname === '/celula'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {celulaCoordenacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/celula-coordenacao"
              selected={pathname === '/celula-coordenacao'}
            >
              <ListItemText primary="Coordenação" />
            </ListItem>
          )}
          {celulaSupervisao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/celula-supervisao"
              selected={pathname === '/celula-supervisao'}
            >
              <ListItemText primary="Supervisão" />
            </ListItem>
          )}
          {celulaLocalizador && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/celula-localizador"
              selected={pathname === '/celula-localizador'}
            >
              <ListItemText primary="Localizador" />
            </ListItem>
          )}
          {celulaTriagem && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/triagem"
              selected={pathname === '/triagem'}
            >
              <ListItemText primary="Triagem" />
            </ListItem>
          )}
          {celulaTriagemBatizante && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/triagem-batizante"
              selected={pathname === '/triagem-batizante'}
            >
              <ListItemText primary="Triagem Batizante" />
            </ListItem>
          )}
          {celulaMapa && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/celula-mapa"
              selected={pathname === '/celula-mapa'}
            >
              <ListItemText primary="Mapa" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {contabilidade && (
        <ListItem button onClick={() => handleListOpenClick('contabilidade')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Contabilidade" />
          {openContabilidade ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openContabilidade} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {contabilidadeComprovantes && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/contabilidade-comprovante"
              selected={pathname === '/contabilidade-comprovante'}
            >
              <ListItemText primary="Comprovantes" />
            </ListItem>
          )}
          {contabilidadeDizimistas && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/dizimista"
              selected={pathname === '/dizimista'}
            >
              <ListItemText primary="Dizimistas" />
            </ListItem>
          )}
          {contabilidade && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-culto"
              selected={pathname === '/relatorio-culto'}
            >
              <ListItemText primary="Relatório Culto" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorio && (
        <ListItem button onClick={() => handleListOpenClick('relatorio')}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
          {openRelatorio ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorio} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioListaPresencaEscolaSabedoria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lista-presenca-escola-sabedoria"
              selected={pathname === '/lista-presenca-escola-sabedoria'}
            >
              <ListItemText primary="Lista Presença Escola Sabedoria" />
            </ListItem>
          )}
          {relatorioListaPresencaReuniao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/lista-presenca-reuniao"
              selected={pathname === '/lista-presenca-reuniao'}
            >
              <ListItemText primary="Lista Presença Reunião" />
            </ListItem>
          )}
        </List>
      </Collapse>
      <List>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>

      {/* <List>
        <ListItem
          button
          component={Link}
          to="/grupo-usuario"
          selected={pathname === '/grupo-usuario'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Membros" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/departamento"
          selected={pathname === '/departamento'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Departamentos" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/lideranca"
          selected={pathname === '/lideranca'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Liderança" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/casa-paz"
          selected={pathname === '/casa-paz'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Casas de Paz" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/celula"
          selected={pathname === '/celula'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Células" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/grupo"
          selected={pathname === '/grupo'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Grupos" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/contabilidade"
          selected={pathname === '/contabilidade'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Contabilidade" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/visitante"
          selected={pathname === '/visitante'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Visitantes" />
        </ListItem>
      </List>
      <List>
        <ListItem
          button
          component={Link}
          to="/membro"
          selected={pathname === '/membro'}
        >
          <ListItemIcon>
            <CadastroIcon countList={0} />
          </ListItemIcon>
          <ListItemText primary="Membros" />
        </ListItem>
      </List> */}
    </div>
  );

  return (
    <>
      <Hidden mdUp>
        <DrawerMui
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </DrawerMui>
      </Hidden>
      <Hidden smDown>
        <DrawerMui
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </DrawerMui>
      </Hidden>
    </>
  );
};

export default AppDrawer;
